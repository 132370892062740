import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

const INIT_CREDITS_POPUP_NAME = '20_free_credits';

export const useInitCreditsPopupAnalytics = () => {
  const trackInitCreditsPopupShow = useCallback(() => {
    TrackingApi.trackPopup({
      name: INIT_CREDITS_POPUP_NAME,
      operation: 'show',
      url: window.location.href,
    }).catch();
  }, []);

  const trackInitCreditsPopupClose = useCallback(() => {
    TrackingApi.trackPopup({
      name: INIT_CREDITS_POPUP_NAME,
      operation: 'close',
      url: window.location.href,
    }).catch();
  }, []);

  const trackInitCreditsPopupClick = useCallback(() => {
    TrackingApi.trackPopup({
      name: INIT_CREDITS_POPUP_NAME,
      operation: 'click',
      url: window.location.href,
    }).catch();
  }, []);

  return {
    trackInitCreditsPopupShow,
    trackInitCreditsPopupClose,
    trackInitCreditsPopupClick,
  };
};
