import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatStickerPack } from 'types/interfaces/chat/ChatStickerPack';
import { UserContact } from 'types/interfaces/user/UserContact';

import { httpClient } from './httpClient';

interface DialogsResponse {
  data: ChatDialog[];
  next: string | null;
}

interface ChatRequestsResponse {
  data: ChatDialog[];
  links: {
    next: string | null;
  };
  total: number;
}

interface MessagesResponse {
  next: string | null;
  is_liked: boolean;
  is_real_gift_available: boolean;
  dialog: ChatDialog;
  contact: UserContact;
  messages: ChatMessage[];
}

interface SendTextMessagePayload {
  contactId: string;
  body: string;
  frontMessageId: string;
}

interface SendPhotoMessagePayload {
  format: ChatMessageFormat.Photo;
  contactId: string;
  photoId: number;
}

interface SendStickerMessagePayload {
  contactId: string;
  stickerId: number;
  frontMessageId: string;
}

interface SendGiftMessagePayload {
  contactId: string;
  giftId: number;
  body: string;
  packageId: string;
  frontMessageId: string;
}

const readIncomeMessage = (message: ChatMessage) => {
  if (!message.is_incoming) {
    return message;
  }

  return {
    ...message,
    read_at: new Date().toUTCString(),
  };
};

export const DialogsApi = {
  async fetchDialogs(url?: string | null) {
    const { data } = await httpClient.get<DialogsResponse>(url || '/dialogs');

    return data;
  },

  async fetchDialogsWithFilters(filters: ChatDialogFilters) {
    const { data } = await httpClient.get<DialogsResponse>(
      `/dialogs?search=${filters.search}`
    );

    return data;
  },

  async updateDialogHideStatus(contactId: string, shouldHide: boolean) {
    const { data } = await httpClient.post<{ status: boolean }>(
      '/dialogs/hide',
      {
        contact_id: contactId,
        hide: shouldHide,
      }
    );

    return data;
  },

  async fetchChatRequests(url?: string | null) {
    const { data } = await httpClient.get<ChatRequestsResponse>(
      url || '/request'
    );

    return data;
  },

  async restoreChatRequests(dialogId: string) {
    const { data } = await httpClient.get<DialogsResponse>(
      `/request/restore/${dialogId}`
    );

    return data;
  },

  async markChatRequestsAsRead(messageId: string) {
    const { data } = await httpClient.get<DialogsResponse>(
      `/request/mark-read-message/${messageId}`
    );

    return data;
  },

  async fetchMessages(contactId: string) {
    const { data } = await httpClient.get<MessagesResponse>(
      `/dialogs/${contactId}`,
      {
        params: {
          page_load_id: window?.pageLoadId,
        },
      }
    );

    return {
      ...data,
      messages: data.messages.map(readIncomeMessage),
    };
  },

  async restoreRequestMessages(payload: {
    contactId: string;
    messageId: string | null;
  }) {
    const { data } = await httpClient.get<{ data: ChatDialog }>(
      payload.messageId
        ? `/request/${payload.contactId}/restore/${payload.messageId}`
        : `/request/${payload.contactId}/restore`
    );

    return data;
  },

  async restoreDialogMessages(payload: {
    contactId: string;
    messageId: string | null;
  }) {
    const { data } = await httpClient.post<{ result: boolean }>(
      `/dialogs/restore`,
      {
        contact_id: payload.contactId,
        ...(payload.messageId && { message_id: payload.messageId }),
      }
    );

    return data;
  },

  async fetchMoreMessages(url: string) {
    const { data } = await httpClient.get<MessagesResponse>(url);

    return data;
  },

  async readIncomingMessages(messageId: number) {
    const { data } = await httpClient.post(`/messages/${messageId}/read`);

    return data;
  },

  async readChatRequest(requestId: number) {
    const { data } = await httpClient.get(
      `/request/mark-read-message/${requestId}`
    );

    return data;
  },

  async sendTextMessage(payload: SendTextMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Text,
      body: payload.body,
      contact_id: payload.contactId,
      front_message_id: payload.frontMessageId,
    });
  },

  async sendPhotoMessage(payload: SendPhotoMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Photo,
      photo_id: payload.photoId,
      contact_id: payload.contactId,
    });
  },

  async sendStickerMessage(payload: SendStickerMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Sticker,
      sticker_id: payload.stickerId,
      contact_id: payload.contactId,
      front_message_id: payload.frontMessageId,
    });
  },

  async sendGiftMessage(payload: SendGiftMessagePayload) {
    return httpClient.post<{ message: ChatMessage }>('/messages', {
      format: ChatMessageFormat.Gift,
      contact_id: payload.contactId,
      gift_id: payload.giftId,
      body: payload.body,
      package_id: payload.packageId,
      front_message_id: payload.frontMessageId,
    });
  },

  async retrySendMessage(messageId: number) {
    const { data } = await httpClient.post<{ message: ChatMessage }>(
      `/messages/${messageId}/retry`
    );

    return data;
  },

  async fetchGifts() {
    const { data } = await httpClient.get<{ data: ChatGift[] }>('/gifts');

    return data;
  },

  async fetchStickerPacks() {
    const { data } = await httpClient.get<{ data: ChatStickerPack[] }>(
      '/sticker-packs'
    );

    return data;
  },

  async fetchWSConnectionToken() {
    const { data } = await httpClient.get<{ token: string }>(
      '/broadcasting/token'
    );

    return data;
  },

  async startTyping({ contactId }: { contactId: string }) {
    return httpClient.get(`/messages/typing/${contactId}`);
  },
};
