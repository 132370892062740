import queryString from 'query-string';

import { QueryKeys } from 'hooks/useSystemSearchQueries';

export type DefaultFunnelParams = {
  [QueryKeys.SuccessUrlPathname]: string;
};

export type DialogueGiftFunnelParams = {
  [QueryKeys.MinCreditsIncome]: number;
  [QueryKeys.ActiveGiftId]: number;
};

export type PresentFunnelParams = {
  [QueryKeys.MinCreditsIncome]: number;
  [QueryKeys.ActivePresentId]: number;
};

export enum Funnel {
  DialogueSticker = 'dialogue_sticker',
  DialogueGift = 'dialogue_gift',
  Present = 'present',
  Message = 'message',
  MessagePhoto = 'message_photo',
  MailSend = 'mail_send',
  MailRead = 'mail_read',
  MailPhoto = 'mail_photo',
  HeaderButton = 'header_button',
  MyProfile = 'my_profile',
  NewChatLowCredits = 'new_chat_low_cr',
  ChatLowCredits = 'chat_low_cr',
}

export function getFunnelSearchString(
  funnel: Funnel.DialogueSticker,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.DialogueGift,
  params: DialogueGiftFunnelParams & DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.Message,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.MessagePhoto,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.MailSend,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.MailRead,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.MailPhoto,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.HeaderButton,
  params: DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel.MyProfile,
  params: DefaultFunnelParams
): string;
export function getFunnelSearchString(
  funnel: Funnel.ChatLowCredits,
  params: DefaultFunnelParams
): string;
export function getFunnelSearchString(
  funnel: Funnel.NewChatLowCredits,
  params: DefaultFunnelParams
): string;
export function getFunnelSearchString(
  funnel: Funnel.Present,
  params: PresentFunnelParams & DefaultFunnelParams
): string;

export function getFunnelSearchString(
  funnel: Funnel,
  params?: Record<string, string | number | null>
): string {
  return queryString.stringify({
    funnel,
    ...(Boolean(params) && params),
  });
}
