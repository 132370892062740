export enum ChatMessageFormat {
  Text = 1,
  Photo = 2,
  Sticker = 3,
  Video = 4,
  Icebreaker = 5,
  Welcome = 6,
  LetsTalk = 7,
  Gift = 8,
  Present = 9,
}
