import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import {
  getIsConfirmationProfilePopupShown,
  getIsConfirmationResent,
  getIsInitedMailConfirmationRetryPopup,
  getIsMailConfirmationRetryPopupShown,
  initMailConfirmationRetryPopup,
  reInitMailConfirmationRetryPopup,
} from 'helpers/emailConfirmation';
import {
  getIsAuthenticated,
  getUser,
  getUserEmailSubscriptionStatus,
} from 'store/auth/selectors';
import {
  getIsEnabledConfirmationSessionSelector,
  getIsWizardFinishedSelector,
  getPrevPagePathname,
} from 'store/common/selectors';
import { getLastActiveUserProfileInfo } from 'store/profile/selectors';
import { getIsActiveMailConfirmPopupSelector } from 'store/systemPopup/selectors';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

const PAGES_TO_IGNORE_CONFIRMATION = [
  '/dialogs',
  '/mails',
  '/upgrade',
  '/payment',
  '/wizard',
  '/user',
  '/disclosures-disclaimers',
  '/terms',
  '-policy',
];

const PAGES_WITH_CONTACT = ['/user', '/dialogs', '/mails'];

export const useMailConfirmationPopups = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);
  const isEnabledConfirmationSession = useSelector(
    getIsEnabledConfirmationSessionSelector
  );
  const isWizardFinished = useSelector(getIsWizardFinishedSelector);
  const prevPagePathname = useSelector(getPrevPagePathname);

  const isActiveMailConfirmPopup = useSelector(
    getIsActiveMailConfirmPopupSelector
  );

  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );
  const lastActiveUserProfile = useSelector(getLastActiveUserProfileInfo);

  const isConfirmationDisabledByPage = useMemo(
    () =>
      PAGES_TO_IGNORE_CONFIRMATION.some((pageItem) =>
        pathname.includes(pageItem)
      ),
    [pathname]
  );

  const isPrevPageUserPage = useMemo(
    () =>
      PAGES_WITH_CONTACT.some((pageItem) =>
        prevPagePathname.includes(pageItem)
      ),
    [prevPagePathname]
  );

  useEffect(() => {
    if (
      lastActiveUserProfile &&
      lastActiveUserProfile?.ulid_id !== user?.ulid_id &&
      userEmailSubscriptionStatus ===
        EmailSubscriptionStatus.ConfirmationSend &&
      isPrevPageUserPage &&
      !getIsConfirmationProfilePopupShown() &&
      !isConfirmationDisabledByPage &&
      !getIsConfirmationResent()
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.MailConfirmAfterProfile,
        })
      );
    }
  }, [
    dispatch,
    isAuthenticated,
    isConfirmationDisabledByPage,
    isPrevPageUserPage,
    lastActiveUserProfile,
    user?.ulid_id,
    userEmailSubscriptionStatus,
  ]);

  useEffect(() => {
    if (
      isWizardFinished &&
      isEnabledConfirmationSession &&
      userEmailSubscriptionStatus ===
        EmailSubscriptionStatus.ConfirmationSend &&
      !getIsInitedMailConfirmationRetryPopup()
    ) {
      initMailConfirmationRetryPopup();
    }
  }, [
    isEnabledConfirmationSession,
    isWizardFinished,
    userEmailSubscriptionStatus,
  ]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isEnabledConfirmationSession &&
      getIsMailConfirmationRetryPopupShown() &&
      !isConfirmationDisabledByPage
    ) {
      if (isActiveMailConfirmPopup) {
        initMailConfirmationRetryPopup();

        return;
      }

      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.MailConfirmAfterReg,
          params: { isRetry: true },
        })
      );

      reInitMailConfirmationRetryPopup();
    }
  }, [
    dispatch,
    isActiveMailConfirmPopup,
    isAuthenticated,
    isConfirmationDisabledByPage,
    isEnabledConfirmationSession,
  ]);
};
