import { MediaType } from 'types/enums/MediaType';
import { Photo } from 'types/interfaces/Photo';
import { Video } from 'types/interfaces/Video';

import { httpClient } from './httpClient';

export interface UploadMediaPayload {
  file: File;
}

interface MediaAccessPayload {
  media_id: number;
  media_type: MediaType;
}

export const MediaApi = {
  async uploadProfilePublicPhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    const { data } = await httpClient.post<Photo>(
      '/user/photo/upload',
      formData
    );

    return data;
  },

  async uploadProfilePrivatePhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    const { data } = await httpClient.post<Photo>(
      '/user/private-photo/upload',
      formData
    );

    return data;
  },

  async uploadPrivateVideo(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('video', payload.file);

    const { data } = await httpClient.post<Video>(
      '/user/video/upload',
      formData
    );

    return data;
  },

  async uploadConversationPhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    return httpClient.post<Photo>('/conversation/photo/upload', formData);
  },

  async uploadInMailPhoto(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('photo', payload.file);

    return httpClient.post<Photo>('/inmail/photo/upload', formData);
  },

  async uploadConversationVideo(payload: UploadMediaPayload) {
    const formData = new FormData();
    formData.append('video', payload.file);

    return httpClient.post<Photo>('/conversation/video/upload', formData);
  },

  async getMediaAccesses(contactId: string) {
    return httpClient.post<{
      accesses: Record<MediaType, string[]>;
      mass_accesses: MediaType[];
    }>(`/user/media/accesses/${contactId}`);
  },

  async grantMediaAccesses(payload: MediaAccessPayload) {
    return httpClient.post<{ media: Photo }>(
      '/user/media/grant-access',
      payload
    );
  },

  async checkMediaAccesses(payload: MediaAccessPayload) {
    return httpClient.post<{ access: boolean }>(
      '/user/media/has-access',
      payload
    );
  },
};
