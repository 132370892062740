import { MirrorService } from 'services/MirrorService';
import {
  ConversionEventType,
  FacebookConversionEventName,
  GtmConversionEventName,
} from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';

export const Gtm = {
  init(params: { gtmId: string }) {
    const { gtmId } = params;

    if (!gtmId) {
      return;
    }

    const innerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    const noscriptInner = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    script.innerHTML = innerScript;
    noscript.innerHTML = noscriptInner;
  },

  sendEvent(payload: { event: GtmConversionEventName }) {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer?.push(payload);

    ConversionsApi.forceGtmConversion({ eventName: payload.event });
  },

  userRegEvent() {
    this.sendEvent({ event: GtmConversionEventName.Registration });
  },

  userMailConfirm() {
    this.sendEvent({ event: GtmConversionEventName.MailConfirm });
  },

  userSale() {
    this.sendEvent({ event: GtmConversionEventName.Sale });
  },

  userResale() {
    this.sendEvent({ event: GtmConversionEventName.Resale });
  },

  userCardSubmit() {
    this.sendEvent({ event: GtmConversionEventName.CardSubmit });
  },

  userSpendFirstCredit() {
    this.sendEvent({ event: GtmConversionEventName.SpendFirstCredit });
  },

  userSpend15Credits() {
    this.sendEvent({ event: GtmConversionEventName.Spend15Credits });
  },

  engagedUserV1() {
    this.sendEvent({ event: GtmConversionEventName.EngagedUserV1 });
  },

  trackConversionEventByName(eventData: any) {
    const eventName = eventData?.event;

    if (!MirrorService.gtmId) return;

    switch (eventName) {
      case FacebookConversionEventName.CompleteRegistration: {
        this.userRegEvent();

        break;
      }
      case FacebookConversionEventName.AddToWishlist: {
        this.userMailConfirm();

        break;
      }
      case FacebookConversionEventName.Purchase: {
        this.userSale();

        break;
      }
      case FacebookConversionEventName.FindLocation: {
        this.userCardSubmit();

        break;
      }
      case FacebookConversionEventName.UserSpent1cr: {
        this.userSpendFirstCredit();

        break;
      }
      case FacebookConversionEventName.UserSpent15cr: {
        this.userSpend15Credits();

        break;
      }
      case FacebookConversionEventName.EngagedUserV1: {
        this.engagedUserV1();

        break;
      }

      default:
        break;
    }
  },

  trackConversionEventByType(eventType: ConversionEventType) {
    switch (eventType) {
      case ConversionEventType.Registration: {
        this.userRegEvent();

        break;
      }
      case ConversionEventType.MailConfirm: {
        this.userMailConfirm();

        break;
      }
      case ConversionEventType.FirstPurchase: {
        this.userSale();

        break;
      }
      case ConversionEventType.FindLocation: {
        this.userCardSubmit();

        break;
      }
      case ConversionEventType.UserSpent1cr: {
        this.userSpendFirstCredit();

        break;
      }
      case ConversionEventType.UserSpent15cr: {
        this.userSpend15Credits();

        break;
      }
      case ConversionEventType.EngagedUserV1: {
        this.engagedUserV1();

        break;
      }

      default:
        break;
    }
  },
};
