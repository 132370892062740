import React, { memo, useCallback } from 'react';
import cx from 'classnames';

import { MirrorService } from 'services/MirrorService';

import { IS_PWA_ON_MAIL_CONFIRM_SHOWN } from 'helpers/pwa/constants';
import { setSessionStorageItem } from 'helpers/sessionStorage';
import { useDeleteQueryByName } from 'hooks/useDeleteQueryByName';
import { QueryKeys } from 'hooks/useSystemSearchQueries';

import first from 'assets/wizards/first.webp';
import forth from 'assets/wizards/forth.webp';
import second from 'assets/wizards/second.webp';
import third from 'assets/wizards/third.webp';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Favicon } from 'components/shared/Favicon';
import { Popup } from 'components/shared/Popup';

import css from './promotePWAPopup.module.sass';

interface Props {
  onSubmit: () => void;
  onClose: () => void;
}

export const WizardPopupContent: React.FC<Props> = memo(
  ({ onClose, onSubmit }) => {
    const { deleteQueryByName } = useDeleteQueryByName();

    const handleSubmit = useCallback(() => {
      deleteQueryByName(QueryKeys.StarterPopup);
      onSubmit();
    }, [deleteQueryByName, onSubmit]);

    const handleClose = useCallback(() => {
      deleteQueryByName(QueryKeys.StarterPopup);
      setSessionStorageItem(IS_PWA_ON_MAIL_CONFIRM_SHOWN, true);
      onClose();
    }, [deleteQueryByName, onClose]);

    return (
      <Popup onClose={handleClose} popupClassName={css.noSpace}>
        <div className={css.wizardRoot}>
          <div className={css.imagesWrapper}>
            <div className={css.imageWrapper}>
              <img
                src={MirrorService.resolveImagePath(first)}
                className={css.image}
                alt="ladies"
              />
            </div>
            <div className={css.imageWrapper}>
              <img
                src={MirrorService.resolveImagePath(second)}
                className={css.image}
                alt="ladies"
              />
            </div>
            <div className={css.imageWrapper}>
              <img
                src={MirrorService.resolveImagePath(third)}
                className={css.image}
                alt="ladies"
              />
            </div>
            <div className={css.imageWrapper}>
              <img
                src={MirrorService.resolveImagePath(forth)}
                className={css.image}
                alt="ladies"
              />
            </div>
          </div>

          <div>
            <p className={cx(css.text, css.accentText)}>
              Stay just a tap away from connecting.
            </p>
            <p className={css.text}> Enjoy instant access anytime, anywhere!</p>
          </div>

          <BaseButton
            type={ButtonTypes.Transparent}
            className={css.wizardButton}
            onClick={handleSubmit}
          >
            Add to Home Screen
          </BaseButton>
          <div className={css.guide}>
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock}>
              <Favicon className={css.guideMockImage} />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
);
